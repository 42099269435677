import classNames from "classnames";
import * as classes from "./Row.css";

export function Row({
  children,
  flexEnd,
  alignTop,
  alignBottom,
  className,
  grow,
  style = {},
}: {
  children: React.ReactNode;
  flexEnd?: boolean;
  alignTop?: boolean;
  alignBottom?: boolean;
  className?: string;
  grow?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <div
      className={classNames(classes.container, className, {
        [classes.flexEnd]: flexEnd,
        [classes.alignTop]: alignTop,
        [classes.alignBottom]: alignBottom,
        [classes.grow]: grow,
      })}
      style={style}
    >
      {children}
    </div>
  );
}
