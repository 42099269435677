import { Button as MantineButton, type ButtonProps } from "@mantine/core";
import * as classes from "./Button.css";

export function Button(
  props: ButtonProps & {
    type?: "button" | "submit" | "reset";
    onClick?: () => void;
    renderRoot?: ((props: Record<string, unknown>) => unknown) | undefined;
  }
) {
  return (
    <MantineButton
      {...props}
      classNames={{
        root: classes.root,
        ...props.classNames,
      }}
      type={props.type || "button"}
      loaderProps={props.loaderProps || { type: "dots" }}
    />
  );
}
