import { ForwardedRef, forwardRef } from "react";
import {
  useFetcher,
  Form as RemixForm,
  type FormProps,
} from "@remix-run/react";
import * as classes from "./Form.css";

function FormWithRef(
  {
    children,
    fetcher,
    method,
    onSubmit,
  }: FormProps & {
    fetcher?: ReturnType<typeof useFetcher>;
  },
  ref: ForwardedRef<HTMLFormElement>
) {
  const Component = fetcher ? fetcher.Form : RemixForm;

  return (
    <Component
      ref={ref}
      className={classes.form}
      method={method}
      onSubmit={onSubmit}
    >
      {children}
    </Component>
  );
}

export const Form = forwardRef(FormWithRef);
